import React, { Component } from 'react'
import { Button, Table, Modal, Input, Form, Radio, Checkbox, Select, message,Tooltip } from 'antd'
import { sources, theanSweris, ListInva, forbid, enable } from "../../api/invalidAnswer"
import './index.less'
const { Option } = Select;
const data = [
    {
        key: '1',
        name: '答题时长',
        age: "受访者答题时长大于等于: 20秒",
        address: '2020-11-11 12:00',
        afew: "0份",
        sta: "已启用",
    },
    {
        key: '2',
        name: '答题时长',
        age: '受访者答题时长介于： 20～30秒  ',
        address: '2020-11-11 12:00  ',
        afew: "0份",
        sta: "已启用",
    },
    {
        key: '3',
        name: '答题时长',
        age: '答题来源是：原链接分享、邮件分享、微信分享...',
        address: '2020-11-11 12:00',
        afew: "0份",
        sta: "已启用",
    },
];
const times = {
    0: 1,
    1: 60,
    2: 3600
}
export default class HeaderText extends Component {
    state = {
        isModalVisible: false,
        cut: false,
        value: false,
        dataList: [],
        total: 0,
        tip: "",
        time: {
            stadTime: null,
            staSelectTime: 0,
            endTime: null,
            endSelectTime: 0,
        },
        radnum: 0,
        pageNum: 1,
        pageSize: 10,

        timer: [
            {
                label: "秒",
                0: 1,
            }, {
                label: "分",
                1: 60,
            }, {
                label: "时",
                2: 3600

            }
        ]

    }
    //规则列表
    invalidList = () => {
        ListInva({
            libId: JSON.parse(sessionStorage.getItem("router")).result,
            pageSize: this.state.pageSize,
            pageNum: this.state.pageNum
        }).then(res => {
            if (res.code === 0) {
                this.setState({
                    dataList: res.result.data,
                    total: res.result.total
                })
            } else {
                this.mesWarning("数据错误")
            }

        })
    }
    goOn = record => {
        enable({
            libId: JSON.parse(sessionStorage.getItem("router")).result,
            ruleId: record.ruleId
        }).then(res => {
            if (res.code === 0) {
                this.mesSuccess('启用成功');
                this.invalidList();
            }
        })
    }
    //暂停继续
    forbidden = (record) => {
        forbid({
            libId: JSON.parse(sessionStorage.getItem("router")).result,
            ruleId: record.ruleId
        }).then(res => {
            if (res.code === 0) {
                this.setState({
                    tip: "继续"
                })
                this.invalidList();
            } else {
                this.mesWarning("暂停失败")
            }

        })
    }
    componentDidMount() {
        this.invalidList()

    }
    addrule = () => {
        this.setState({ isModalVisible: true }, () => {
            setTimeout(() => {
                this.formRef.current.setFieldsValue({
                    ruleType: '2',
                    ruleKey: '2',
                    password: 2
                })
            })

        })

    }
    // 切换描述
    cutDescribe = (e) => {
        if (e.target.value === '1') {
            this.setState({
                cut: false
            })
        } else if (e.target.value === '2') {
            this.setState({
                cut: true
            })
        }
    }
    // 切换大于介于小于
    betweenness = (e) => {
        this.setState({
            radnum: e.target.value,
        })
        if (e.target.value === 1) {
            this.setState({
                value: true,
                time: {
                    stadTime: null,
                    staSelectTime: 0,
                    endTime: null,
                    endSelectTime: 0,
                }
            })
        } else {
            this.setState({
                value: false
            })
        }
    }
    // 描述规则input
    time = (e, name) => {
        if (isNaN(e)) {
            this.mesWarning('请输入数字')
            return;
        }
        let newAnswer = this.state.time[name] = e;
        this.setState({
            newAnswer
        })
    }
    submit = async () => {
        try {
            let data = await this.formRef.current.validateFields();
            if (data.ruleType === "1") {
                let ruleVal = data.ruleVal?.join(",")
                let send = { ruleKey: data.ruleKey, ruleVal }
            } else if (data.ruleType === "2") {
                this.setState({
                    cut: true
                })
            }
            if (!this.state.cut) {
                let config = {
                    ruleKey: data.ruleType,
                    ruleVal: data.ruleVal.join(',') || '',
                }
                sources({ ruleDesc: config, libId: JSON.parse(sessionStorage.getItem("router")).result }).then(res => {
                    if (res.code !== 0) {
                        this.mesWarning(res.message);
                        return;
                    }
                    this.setState({ isModalVisible: false });
                    let tip = res.message === 'success' ? "添加成功" : '';
                    this.mesSuccess(tip);
                    this.invalidList()
                })
            } else {
                let arr = [];
                arr.push(this.state.time.stadTime);
                arr.push(this.state.time.endTime);

                let str = [];
                str.push(this.state.time.staSelectTime);
                str.push(this.state.time.endSelectTime);
                if (this.state.radnum === 1) {
                    // const {time:{stadTime,endSelectTime}} = this.state;
                    let { time } = this.state;
                    if (time.stadTime * times[time.staSelectTime] < time.endTime * times[time.endSelectTime]) {
                        if (this.state.time.stadTime !== null && this.state.time.endTime !== null) {
                            theanSweris({ ruleDesc: { timeLong: data.password, timeType: str.join(','), time: arr.join(',') }, libId: JSON.parse(sessionStorage.getItem("router")).result }).then(res => {
                                if (res.code === 0) {
                                    this.mesSuccess("添加成功");
                                    this.setState({ isModalVisible: false, cut: false });
                                    this.invalidList();
                                    return;
                                }
                                this.mesWarning("添加失败");
                            })
                        } else {
                            this.mesWarning('请输入开始时间');
                            return;
                        }

                    } else {
                        this.mesWarning('开始时间不可大于结束时间');
                        return;
                    }
                } else {
                    if (this.state.time.stadTime === null) {
                        this.mesWarning("请输入有效数字")
                        return
                    }
                    theanSweris(
                        {
                            ruleDesc: {
                                timeLong: data.password,
                                timeType: this.state.radnum === 0 ? str[0] : this.state.radnum === 2 ? str[0] : str.join(','),
                                time: this.state.radnum === 0 ? arr[0] : this.state.radnum === 2 ? arr[0] : arr.join(',')
                            },
                            libId: JSON.parse(sessionStorage.getItem("router")).result
                        }
                    ).then(res => {
                        if (res.code === 0) {
                            this.setState({ isModalVisible: false, cut: false });
                            this.invalidList()
                            this.mesSuccess("添加成功");
                        } else {
                            this.mesWarning("添加失败");
                        }
                    })
                }

            }
        } catch (err) {
            console.log(err);
        }
    }
    formRef = React.createRef();
    //pageNum变化
    onPageNumChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.invalidList()
        }
        );
    };
    //pageSize变化
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            pageNum: current,
            pageSize: pageSize,
        }, () => {
            this.invalidList()
        }
        );
    };
    render() {
        let { timer } = this.state;
        const columns = [
            {
                title: '规则类型',
                dataIndex: 'ruleType',
                key: 'ruleType',
                render: text => <span>{text === 0 ? "答题来源" : "答题时长"}</span>,
            },
            {
                title: '规则描述',
                dataIndex: 'roleRule',
                key: 'roleRule',
                render: text => <Tooltip placement="top" title={text}>
                    <span className='roleRule'>{text}</span>
                </Tooltip>,
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '无效答卷',
                dataIndex: 'invalidNum',
                key: 'invalidNum',
            },
            {
                title: '状态',
                dataIndex: 'isEnable',
                key: 'isEnable',
                render: text => <span style={{ color: text === 0 ? "#E34949" : "#42CF9D" }}>{text === 0 ? "已暂停" : "已启用"}</span>
            },
            {
                title: '操作',
                dataIndex: 'x',
                key: 'x',
                render: (a, record) => {
                    if (record.isEnable === 0) {
                        return <span style={{ border: "1px solid #F3F3F3", display: "inline-block", width: 70, height: 25 }} onClick={() => this.goOn(record)} id="border">
                            <i style={{ paddingRight: 5 }}>
                                <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.63921 4.38235L1.08726 0.0968992C0.861467 -0.0321715 0.590673 -0.0321714 0.362421 0.096131C0.135806 0.225202 0 0.455685 0 0.713058V9.28627C0 9.54364 0.135806 9.77489 0.362421 9.9032C0.476138 9.96773 0.600491 10 0.724843 10C0.849195 10 0.974366 9.96773 1.08726 9.9032L8.63839 5.61774C8.86501 5.48867 9 5.25742 9 5.00005C9.00081 4.74191 8.86583 4.51066 8.63921 4.38235ZM0.787019 9.18025L0.755931 0.802178L8.15244 5.00005L0.787019 9.18025Z" fill="#888888" />
                                </svg>
                            </i>
                            继续
                        </span>
                    } else {
                        return <span style={{ border: "1px solid #F3F3F3", display: "inline-block", width: 70, height: 25 }} onClick={() => this.forbidden(record)} id="border">
                            <i style={{ paddingRight: 5 }}>
                                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.781006 0C0.349674 0 0 0.362824 0 0.810377V9.93462C0 10.3822 0.349674 10.745 0.781006 10.745C1.21234 10.745 1.56201 10.3822 1.56201 9.93462V0.810377C1.56201 0.362824 1.21234 0 0.781006 0Z" fill="#888888" />
                                    <path d="M6.21899 0C5.78766 0 5.43799 0.362824 5.43799 0.810377V9.93462C5.43799 10.3822 5.78766 10.745 6.21899 10.745C6.65033 10.745 7 10.3822 7 9.93462V0.810377C7 0.362824 6.65033 0 6.21899 0Z" fill="#888888" />
                                </svg>
                            </i>
                            暂停
                        </span>
                    }
                }
            },
        ]
        return (
            <div className="answertherules">
                <div className='HeaderText'>
                    <div onClick={() => this.props.history.goBack()}>
                        <i>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.00642 4.01977H1.73841L5.61202 0.91489C5.66958 0.876625 5.71858 0.826822 5.75589 0.768635C5.7932 0.710448 5.81802 0.645146 5.82878 0.576865C5.83954 0.508585 5.836 0.438814 5.81838 0.371973C5.80077 0.305132 5.76947 0.242678 5.72645 0.188567C5.68344 0.134456 5.62966 0.0898674 5.56852 0.0576263C5.50738 0.0253852 5.4402 0.00619414 5.37126 0.00126885C5.30231 -0.00365644 5.23309 0.0057914 5.16798 0.0290139C5.10288 0.0522363 5.04331 0.0887272 4.99304 0.136175L0.00127564 4.12959V4.30929C-0.000425212 4.33255 -0.000425212 4.35591 0.00127564 4.37917V4.42909C-0.000425212 4.45236 -0.000425212 4.47571 0.00127564 4.49898V4.67868L4.99304 8.67209C5.04417 8.71339 5.10294 8.74421 5.16598 8.7628C5.22902 8.78139 5.29511 8.78737 5.36047 8.78042C5.42582 8.77347 5.48917 8.75371 5.54689 8.72228C5.60462 8.69084 5.65558 8.64835 5.69688 8.59721C5.73818 8.54608 5.769 8.48732 5.78759 8.42428C5.80617 8.36123 5.81216 8.29515 5.80521 8.22979C5.79826 8.16443 5.7785 8.10108 5.74706 8.04336C5.71563 7.98564 5.67313 7.93467 5.622 7.89338L1.73841 5.01812H9.00642C12.1912 5.01812 14.7869 7.25443 14.7869 10.0099C14.7869 12.7653 12.1912 15.0016 9.00642 15.0016H1.30912C1.17673 15.0016 1.04976 15.0542 0.956147 15.1479C0.862533 15.2415 0.809942 15.3684 0.809942 15.5008C0.809942 15.6332 0.862533 15.7602 0.956147 15.8538C1.04976 15.9474 1.17673 16 1.30912 16H9.00642C12.7902 16 15.7852 13.3144 15.7852 10.0099C15.7852 6.70534 12.7902 4.01977 9.00642 4.01977Z" fill="#333333" />
                            </svg>
                        </i>
                        <span style={{ margin: "0px 0px 0px 10px" }}>
                            返回
                        </span>
                    </div>
                    <div className="HeaderTextright">
                        无效问卷规则
                    </div>
                    <div className="HeaderTextBtn">
                        <button style={{ background: "#3E78ED", border: "none", color: "#FFFFFF", height: "32px" }} onClick={() => this.addrule()}>添加筛选规则</button>
                    </div>
                </div>
                <div className='therules_wai'>
                    <div className="therules">
                        <div className="therules_title">
                            <i>
                                <svg width="4" height="19" viewBox="0 0 4 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="4" height="19" rx="2" fill="url(#paint0_linear)" />
                                    <defs>
                                        <linearGradient id="paint0_linear" x1="0.285714" y1="-4.97496e-07" x2="10.7536" y2="3.65113" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#6EA8FF" />
                                            <stop offset="1" stopColor="#485AFE" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </i>
                            <span>筛选规则</span>
                            <p>满足以下任一筛选规则的受访者将提前结束答题，系统将保存已作答内容，并将答卷标记为无效答卷</p>
                            <div className="therules_type">
                                <Table rowKey={(red) => red.ruleId}
                                    pagination={{
                                        total: this.state.total,
                                        showSizeChanger: true,
                                        onShowSizeChange: (a, b) => this.onShowSizeChange(a, b),
                                        onChange: (a, b) => this.onPageNumChange(a, b),
                                        current: this.state.pageNum,
                                        pageSize: this.state.pageSize,
                                    }}
                                    
                                    locale={{
                                        emptyText:
                                            <div className="therules_box">
                                                <img src={window.$$setImgSrc('QN_invaildNull.jpg')} />
                                                <p className="therules_p" style={{ color: "#222222" }}>
                                                    该项目暂无筛选规则，点击<a style={{ borderBottom: "1px solid #3E78ED" }} onClick={() => this.addrule({ isModalVisible: true })}>添加筛选规则</a> 进行设置
                                                </p>
                                            </div>
                                    }}
                                    columns={columns}
                                    dataSource={this.state.dataList}

                                />

                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    width={630}
                    className="bounced" title="添加无效答卷筛选规则"
                    visible={this.state.isModalVisible}
                    onOk={() => this.submit()}
                    destroyOnClose={true}
                    centered={true}
                    onCancel={() => {
                        this.setState({ isModalVisible: false })
                        // this.formRef.current.resetFields();
                        this.setState({
                            cut: false,
                            value: false
                        })
                    }}

                >
                    <Form ref={this.formRef}>
                        <Form.Item
                            label="规则类型"
                            name="ruleType"
                            rules={[{ required: false, message: 'Please input your username!' }]}
                        >
                            <Radio.Group onChange={(e) => this.cutDescribe(e)} defaultValue='2'>
                                {/*<Radio value="1">来源渠道</Radio>*/}
                                <Radio value='2' checked={true}>答题时长</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {/*{!this.state.cut ? <div>*/}
                        {/*    <Form.Item*/}
                        {/*        label="规则描述"*/}
                        {/*        name="ruleKey"*/}
                        {/*        rules={[{ required: false, message: 'Please input your password!' }]}*/}
                        {/*    >*/}
                        {/*        <Radio.Group>*/}
                        {/*            <Radio value="1">答题来源是</Radio>*/}
                        {/*            <Radio value="2">答题来源不是</Radio>*/}
                        {/*        </Radio.Group>*/}
                        {/*    </Form.Item>*/}
                        {/*    <Form.Item style={{ paddingLeft: "69px" }}*/}
                        {/*        label=""*/}
                        {/*        name="ruleVal"*/}
                        {/*        rules={[{ required: false, message: 'Please input your password!' }]}*/}
                        {/*    >*/}
                        {/*        <Checkbox.Group>*/}
                        {/*            <Checkbox value="YL" >*/}
                        {/*                原链接分享*/}
                        {/*            </Checkbox>*/}
                        {/*            <Checkbox value="YJ" >*/}
                        {/*                邮件分享*/}
                        {/*            </Checkbox>*/}
                        {/*            <Checkbox value="DX" >*/}
                        {/*                短信分享*/}
                        {/*            </Checkbox>*/}
                        {/*            <Checkbox value="WX" >*/}
                        {/*                微信分享*/}
                        {/*            </Checkbox>*/}
                        {/*        </Checkbox.Group>*/}
                        {/*    </Form.Item>*/}
                        {/*    <p id="bounced_p">*符合以上设置条件的答卷都将记为无效答卷*</p>*/}
                        {/*</div> : */}
                            <div className="bounced_div">
                            <Form.Item
                                label="规则描述"
                                name="password"
                                rules={[{ required: false, message: 'Please input your password!' }]}
                            >
                                <Radio.Group onChange={(e) => this.betweenness(e)}>
                                    <Radio value={2}>大于等于</Radio>
                                    <Radio value={0}>小于等于</Radio>
                                    <Radio value={1}>介于</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <div>
                                <p className="time_ipt">

                                    <Input style={{ width: "101px", height: "30px" }} placeholder="请输入数字" onChange={(e) => this.time(e.target.value, "stadTime")} />
                                    <Select defaultValue={0} style={{ width: "101px", height: "32px" }} onChange={(e) => this.time(e, "staSelectTime")}>
                                        {
                                            timer.map((v, i) => <Option value={i}>{v.label}</Option>)
                                        }
                                    </Select>
                                </p>
                                <p id="bounced_p">*符合以上设置条件的答卷都将记为无效答卷*</p>
                            </div>

                            {this.state.value ?
                                <span style={{ position: "absolute", top: "58%", right: "63px", }}>
                                    <span style={{ padding: "0 10px" }}>至</span>
                                    < span ><Input style={{ width: "101px", height: "30px", marginRight: "20px" }} placeholder="请输入数字" onChange={(e) => this.time(e.target.value, "endTime")} />
                                        <Select defaultValue={0} style={{ width: "101px", height: "32px" }} onChange={(e) => this.time(e, "endSelectTime")}>
                                            {
                                                timer.map((v, i) => <Option value={i}>{v.label}</Option>)
                                            }
                                        </Select></span>
                                </span> : null}
                        </div>
                        {/* }*/}
                    </Form>
                </Modal>
            </div >
        )
    }
}
